import React, { useEffect, useState } from 'react';
import { Button, Table, notification, Popconfirm, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import BranchInventoryGroup from "../../../../../http/BranchInventoryGroup";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import BranchInventory from "../../../../../http/BranchInventory";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const ManageBranchInventoryGroups = () => {
    const [loading, setLoading] = useState(true);
    const [branchInventoryGroups, setBranchInventoryGroups] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('all');
    const history = useHistory();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        BranchInventory.FetchInitialData((response) => {
            const { branches } = response.data.data;
            setBranches(branches);
            fetchBranchInventoryGroups();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load initial data',
            });
        });
    };

    const fetchBranchInventoryGroups = () => {
        setLoading(true);
        BranchInventoryGroup.Index((response) => {
            setBranchInventoryGroups(response.data.data.branch_inventory_groups);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branch inventory groups',
            });
        });
    };

    const handleDelete = (id) => {
        setLoading(true);
        BranchInventoryGroup.Delete(id, (response) => {
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            fetchBranchInventoryGroups();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to delete branch inventory group',
            });
        });
    };

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
    };

    const filteredBranchInventoryGroups = selectedBranch === 'all' ? branchInventoryGroups : branchInventoryGroups.filter(bi => bi.branch.id === selectedBranch);

    const columns = [
        {
            title: 'Branch',
            dataIndex: ['branch', 'name'],
            key: 'branch',
        },
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div className={"flex flex-row items-center"}>
                {text}
                    {record.description || record.image_url ? (
                        <Tooltip
                            title={
                                <div>
                                    {record.description && <p>{record.description}</p>}
                                    {record.image_url && (
                                        <img
                                            src={record.image_url}
                                            alt="Inventory Group"
                                            style={{ maxWidth: '100px', marginTop: '10px' }}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <InfoCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#1890ff' }} />
                        </Tooltip>
                    ) : null}
            </div>
            ),
        },
        {
            title: 'Unit Type',
            dataIndex: 'unit_type',
            key: 'unit_type',
        },
        {
            title: 'Vendor',
            dataIndex: ['vendor', 'name'],
            key: 'vendor',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                <Link to={`/dashboard/operations/setup/inventory/branch-group/${record.id}/edit`}>
                    <Button icon={<EditOutlined />} />
                </Link>
                <Popconfirm
                    title="Are you sure to delete this group?"
                    onConfirm={() => handleDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button icon={<DeleteOutlined />} className="ml-2" />
                </Popconfirm>
            </span>
            ),
        },
    ];

    const onBranchInventoryGroupCreate = () => {
        history.push("/dashboard/operations/setup/inventory/branch-group/new");
    }

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full">
                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row w-full">
                    <div className={"flex flex-row items-center justify-between w-full"}>
                        <span className={""}>Manage Branch Inventory Groups</span>
                        <Button onClick={onBranchInventoryGroupCreate} style={{ borderRadius: "5px" }} type={"primary"} className={"primary-button"}>
                            <div className={"flex flex-row items-center"}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-lg cursor-pointer" aria-hidden="true" />
                                Add Branch Inventory Group
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="mb-4 pl-1 text-gray-600">
                    <p>
                        In this section you can specify which inventories at each branch are supplied.
                    </p>
                </div>
                <div className="mb-4">
                    <Select placeholder="Filter by Branch" onChange={handleBranchChange} className="w-full">
                        <Option value="all">All Branches</Option>
                        {branches.map(branch => (
                            <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                        ))}
                    </Select>
                </div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Table
                        dataSource={filteredBranchInventoryGroups}
                        columns={columns}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                    />
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageBranchInventoryGroups;
