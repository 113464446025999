import React, {Suspense, useEffect, useState} from 'react';
import {Switch, Route, useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import DashboardIndex from "../pages/User/Dashboard/Index/Index";
import Announcements from "../pages/User/Dashboard/Annoucements/Annoucements";
import KPI from "../pages/User/Dashboard/KPI/index";
import Survey from "../pages/User/Dashboard/Survey/SurveyForm";
import AdminLayout from "../pages/User/Dashboard/AdminLayout";
import Requests from "../pages/User/Dashboard/Requests/Requests";
import MarkBloggers from "../pages/User/Dashboard/Bloggers/mark";
import InviteBloggers from "../pages/User/Dashboard/Bloggers/invite";
import BloggerSettings from "../pages/User/Dashboard/Bloggers/settings";
import InviteesBloggers from "../pages/User/Dashboard/Bloggers/invitees";
import BloggerAttends from "../pages/User/Dashboard/Bloggers/attends";
import CompanyTree from "../pages/User/Dashboard/Divisions/Tree/CompanyTree";
import DivisionIndex from "../pages/User/Dashboard/Divisions/DivisionIndex";
import DivisionDetails from "../pages/User/Dashboard/Divisions/DivisionDetails";
import DivisionTimeline from "../pages/User/Dashboard/Divisions/Timeline/DivisionTimeline";
import TaskGroupDetails from "../pages/User/Dashboard/Divisions/TaskGroup/TaskGroupDetails";
import MyTasks from "../pages/User/Dashboard/Divisions/MyTasks/MyTasks";
import IraniValidate from "../pages/User/Dashboard/Irani/Validation/IraniValidate";
import IraniReports from "../pages/User/Dashboard/Irani/Reports/IraniReports";
import IraniKhazBrands from "../pages/User/Dashboard/Irani/Validation/IraniKhazBrands";
import IraniStandardBrands from "../pages/User/Dashboard/Irani/Validation/IraniStandardBrands";
import IraniFancyBrands from "../pages/User/Dashboard/Irani/Validation/IraniFancyBrands";
import IraniContextReports from "../pages/User/Dashboard/Irani/ContextReports/IraniContextReports";
import IraniReviewReports from "../pages/User/Dashboard/Irani/ReviewReports/IraniReviewReports";
import USAValidate from "../pages/User/Dashboard/USA/Validation/USAValidate";
import USAKhazBrands from "../pages/User/Dashboard/USA/Validation/USAKhazBrands";
import USAStandardBrands from "../pages/User/Dashboard/USA/Validation/USAStandardBrands";
import USAFancyBrands from "../pages/User/Dashboard/USA/Validation/USAFancyBrands";
import BigBangIndex from "../pages/User/Dashboard/BigBang/BigBangIndex";
import BigBangReports from "../pages/User/Dashboard/BigBang/BigBangReports";
import GoogleIndex from "../pages/User/Dashboard/Google/GoogleIndex";
import ManageEmployees from "../pages/User/Dashboard/Employees/ManageEmployees";
import ManageTraining from "../pages/User/Dashboard/Training/ManageTraining";
import TrainingChecklists from "../pages/User/Dashboard/Training/TrainingChecklists";
import Places from "../pages/User/Dashboard/Reviews/Places/Places";
import BaseReviewsIndex from "../pages/User/Dashboard/Reviews/BaseReviews/Index";
import OurReviewsIndex from "../pages/User/Dashboard/Reviews/OurReviews/Index";
import ManageDiscountPresets from "../pages/User/Dashboard/Coupons/DiscountPresets/ManageDiscountPresets";
import ManageGeneratePresets from "../pages/User/Dashboard/Coupons/GeneratePresets/ManageGeneratePresets";
import GenerateIndex from "../pages/User/Dashboard/Coupons/Generate/GenerateIndex";
import ApproveCoupons from "../pages/User/Dashboard/Coupons/Generate/ApproveCoupons";
import DubaiValidate from "../pages/User/Dashboard/Dubai/Validation/DubaiValidate";
import DubaiKhazBrands from "../pages/User/Dashboard/Dubai/Validation/DubaiKhazBrands";
import DubaiStandardBrands from "../pages/User/Dashboard/Dubai/Validation/DubaiStandardBrands";
import DubaiFancyBrands from "../pages/User/Dashboard/Dubai/Validation/DubaiFancyBrands";
import CouponReports from "../pages/User/Dashboard/Coupons/Reports/CouponReports";
import ManageDepartments from "../pages/User/Dashboard/Departments/ManageDepartments";
import LondonValidate from "../pages/User/Dashboard/London/Validation/LondonValidate";
import LondonKhazBrands from "../pages/User/Dashboard/London/Validation/LondonKhazBrands";
import LondonStandardBrands from "../pages/User/Dashboard/London/Validation/LondonStandardBrands";
import LondonFancyBrands from "../pages/User/Dashboard/London/Validation/LondonFancyBrands";
import ParisValidate from "../pages/User/Dashboard/Paris/Validation/ParisValidate";
import ParisKhazBrands from "../pages/User/Dashboard/Paris/Validation/ParisKhazBrands";
import ParisStandardBrands from "../pages/User/Dashboard/Paris/Validation/ParisStandardBrands";
import ParisFancyBrands from "../pages/User/Dashboard/Paris/Validation/ParisFancyBrands";
import EditEmployee from "../pages/User/Dashboard/Employees/EditEmployee";
import FormBuilder from "../pages/User/Dashboard/Forms/FormBuilder";
import FormRenderer from "../pages/User/Dashboard/Forms/FormRenderer";
import FormManagement from "../pages/User/Dashboard/Forms/FormManagement";
import FormSubmissions from "../pages/User/Dashboard/Forms/FormSubmissions";
import FormSubmissionViewer from "../pages/User/Dashboard/Forms/FormSubmissionViewer";
import MasterComponent from "../pages/User/Dashboard/Test/MasterComponent";
import IstanbulValidate from "../pages/User/Dashboard/Istanbul/Validation/IstanbulValidate";
import IstanbulKhazBrands from "../pages/User/Dashboard/Istanbul/Validation/IstanbulKhazBrands";
import IstanbulStandardBrands from "../pages/User/Dashboard/Istanbul/Validation/IstanbulStandardBrands";
import IstanbulFancyBrands from "../pages/User/Dashboard/Istanbul/Validation/IstanbulFancyBrands";
import CanadaValidate from "../pages/User/Dashboard/Canada/Validation/CanadaValidate";
import CanadaKhazBrands from "../pages/User/Dashboard/Canada/Validation/CanadaKhazBrands";
import CanadaStandardBrands from "../pages/User/Dashboard/Canada/Validation/CanadaStandardBrands";
import CanadaFancyBrands from "../pages/User/Dashboard/Canada/Validation/CanadaFancyBrands";

import TehranValidate from "../pages/User/Dashboard/Tehran/Validation/TehranValidate";
import TehranKhazBrands from "../pages/User/Dashboard/Tehran/Validation/TehranKhazBrands";
import TehranStandardBrands from "../pages/User/Dashboard/Tehran/Validation/TehranStandardBrands";
import TehranFancyBrands from "../pages/User/Dashboard/Tehran/Validation/TehranFancyBrands";

import KingcountyValidate from "../pages/User/Dashboard/Kingcounty/Validation/KingcountyValidate";
import KingcountyKhazBrands from "../pages/User/Dashboard/Kingcounty/Validation/KingcountyKhazBrands";
import KingcountyStandardBrands from "../pages/User/Dashboard/Kingcounty/Validation/KingcountyStandardBrands";
import KingcountyFancyBrands from "../pages/User/Dashboard/Kingcounty/Validation/KingcountyFancyBrands";

import ManageInventories from "../pages/User/Dashboard/Inventory/Manage/ManageInventories";
import CreateInventory from "../pages/User/Dashboard/Inventory/Manage/CreateInventory";
import CreateVendor from "../pages/User/Dashboard/Inventory/Vendors/CreateVendor";
import ManageVendors from "../pages/User/Dashboard/Inventory/Vendors/ManageVendors";
import ManageBranchInventories from "../pages/User/Dashboard/Inventory/BranchInventory/ManageBranchInventories";
import CreateBranchInventory from "../pages/User/Dashboard/Inventory/BranchInventory/CreateBranchInventory";
import ManageContainers from "../pages/User/Dashboard/Inventory/Container/ManageContainers";
import CreateOrEditContainer from "../pages/User/Dashboard/Inventory/Container/CreateOrEditContainer";
import InventoryEndOfDay from "../pages/User/Dashboard/Inventory/EndOfDay/InventoryEndOfDay";
import BranchSelector from "../components/BranchSelector";
import BranchCoefficientsComponent from "../pages/User/Dashboard/Coefficients/BranchCoefficientsComponent";
import BranchSettingsComponent from "../pages/User/Dashboard/Coefficients/BranchSettingsComponent";
import RecalculateAllCoefficients from "../pages/User/Dashboard/Coefficients/RecalculateAllCoefficients";
import ModelPerformanceComponent from "../pages/User/Dashboard/Coefficients/ModelPerformanceComponent";
import OrderPage from "../pages/User/Dashboard/Inventory/Orders/OrderPage";
import SurveyReports from "../pages/User/Dashboard/Survey/SurveyReports";
import BranchOrders from "../pages/User/Dashboard/Inventory/BranchOrders/BranchOrders";
import VendorOrders from "../pages/User/Dashboard/Inventory/VendorOrders/VendorOrders";
import ManageBranchInventoryGroups from "../pages/User/Dashboard/Inventory/BranchInventoryGroup/ManageBranchInventoryGroups";
import CreateBranchInventoryGroup from "../pages/User/Dashboard/Inventory/BranchInventoryGroup/CreateBranchInventoryGroup";
import ManageBranchInventoryGroupContainers from "../pages/User/Dashboard/Inventory/BranchInventoryGroupContainer/ManageBranchInventoryGroupContainers";
import CreateOrEditBranchInventoryGroupContainer from "../pages/User/Dashboard/Inventory/BranchInventoryGroupContainer/CreateOrEditBranchInventoryGroupContainer";
import BranchForecasts from "../pages/User/Dashboard/Coefficients/BranchForecasts";
import ManageAbilities from "../pages/User/Dashboard/Employees/ManageAbilities";
import ManageRoles from "../pages/User/Dashboard/Employees/ManageRoles";
import RoleList from "../pages/User/Dashboard/Employees/RoleList";
import EditRoleAbilities from "../pages/User/Dashboard/Employees/EditRoleAbilities";
import DeviceListing from "../pages/User/Dashboard/Terminals/DeviceListing";
import DeviceForm from "../pages/User/Dashboard/Terminals/DeviceForm";
import BranchDailyRecords from "../pages/User/Dashboard/Inventory/Reports/BranchDailyRecords";
import VendorEmailTemplateEditor from "../pages/User/Dashboard/Inventory/EmailTemplates/VendorEmailTemplateEditor";
import ConfirmVendorOrder from "../pages/User/Dashboard/Inventory/VendorOrders/ConfirmVendorOrder";
import EmailTemplatesList from "../pages/User/Dashboard/Inventory/EmailTemplates/EmailTemplatesList";
import VendorOrderCancelTemplateEditor from "../pages/User/Dashboard/Inventory/EmailTemplates/VendorOrderCancelTemplateEditor";
import CreateEndOfShiftReport from "../pages/User/Dashboard/Inventory/EndOfShiftReports/CreateEndOfShiftReport";
import EndOfShiftReportsIndex from "../pages/User/Dashboard/Inventory/EndOfShiftReports/EndOfShiftReportsIndex";
import EndOfShiftReportShow from "../pages/User/Dashboard/Inventory/EndOfShiftReports/EndOfShiftReportShow";
import ListTemperatureZones from "../pages/User/Dashboard/Inventory/TemperatureZones/ListTemperatureZones";
import CreateEditTemperatureZone from "../pages/User/Dashboard/Inventory/TemperatureZones/CreateEditTemperatureZone";
import ListShiftTemperatureReports from "../pages/User/Dashboard/Inventory/ShiftTemperatureReport/ListShiftTemperatureReports";
import CreateShiftTemperatureReport from "../pages/User/Dashboard/Inventory/ShiftTemperatureReport/CreateShiftTemperatureReport";
import ListTrainingTopics from "../pages/User/Dashboard/HR/TrainingTopics/ListTrainingTopics";
import CreateEditTrainingTopics from "../pages/User/Dashboard/HR/TrainingTopics/CreateEditTrainingTopics";
import UserTrainings from "../pages/User/Dashboard/HR/Training/UserTrainings";
import UserTrainingTopics from "../pages/User/Dashboard/HR/Training/UserTrainingTopics";
import SubmitTrainingEvaluation from "../pages/User/Dashboard/HR/Training/SubmitTrainingEvaluation";
import ShowTrainingEvaluations from "../pages/User/Dashboard/HR/Training/ShowTrainingEvaluations";

const DashboardRoutes = () => {
    return (
        <Switch>
            <AdminLayout>
                <Route exact path="/dashboard/index" component={DashboardIndex} />
                <Route exact path="/dashboard/announcements" component={Announcements} />
                <Route exact path="/dashboard/KPI" component={KPI} />

                <Route exact path="/dashboard/hr/employees/manage" component={ManageEmployees} />
                <Route exact path="/dashboard/hr/employees/:id/edit" component={EditEmployee} />
                <Route exact path="/dashboard/hr/employees/new" component={EditEmployee} />
                <Route exact path="/dashboard/hr/employees/abilities" component={ManageAbilities} />
                <Route exact path="/dashboard/hr/employees/roles" component={RoleList} />
                <Route exact path="/dashboard/hr/employees/roles/:roleId/edit" component={EditRoleAbilities} />

                <Route exact path="/dashboard/training/manage" component={ManageTraining} />
                <Route exact path="/dashboard/training/checklists" component={TrainingChecklists} />

                <Route exact path="/dashboard/divisions/tree" component={CompanyTree} />
                <Route exact path="/dashboard/divisions/index" component={DivisionIndex} />
                <Route exact path="/dashboard/divisions/timeline" component={DivisionTimeline} />
                <Route exact path="/dashboard/divisions/open_subjects" component={DivisionDetails} />
                <Route exact path="/dashboard/divisions/my_tasks" component={MyTasks} />

                <Route path="/dashboard/division/:id" component={DivisionDetails} />
                <Route path="/dashboard/task_group/:id" component={TaskGroupDetails} />

                <Route exact path="/dashboard/research/requests" component={Requests} />
                <Route exact path="/dashboard/research/bloggers/mark" component={MarkBloggers} />
                <Route exact path="/dashboard/research/bloggers/invite" component={InviteBloggers} />
                <Route exact path="/dashboard/research/bloggers/invitees" component={InviteesBloggers} />
                <Route exact path="/dashboard/research/bloggers/settings" component={BloggerSettings} />
                <Route exact path="/dashboard/research/bloggers/attends" component={BloggerAttends} />

                <Route exact path="/dashboard/research/irani/validate" component={IraniValidate} />
                <Route exact path="/dashboard/research/irani/khaz_brands" component={IraniKhazBrands} />
                <Route exact path="/dashboard/research/irani/standard_brands" component={IraniStandardBrands} />
                <Route exact path="/dashboard/research/irani/fancy_brands" component={IraniFancyBrands} />
                <Route exact path="/dashboard/research/irani/reports" component={IraniReports} />
                <Route exact path="/dashboard/research/irani/context/reports" component={IraniContextReports} />
                <Route exact path="/dashboard/research/irani/review/reports" component={IraniReviewReports} />

                <Route exact path="/dashboard/research/bigbang/index" component={BigBangIndex} />
                <Route exact path="/dashboard/research/bigbang/reports" component={BigBangReports} />

                <Route exact path="/dashboard/research/usa/validate" component={USAValidate} />
                <Route exact path="/dashboard/research/usa/khaz_brands" component={USAKhazBrands} />
                <Route exact path="/dashboard/research/usa/standard_brands" component={USAStandardBrands} />
                <Route exact path="/dashboard/research/usa/fancy_brands" component={USAFancyBrands} />

                <Route exact path="/dashboard/research/dubai/validate" component={DubaiValidate} />
                <Route exact path="/dashboard/research/dubai/khaz_brands" component={DubaiKhazBrands} />
                <Route exact path="/dashboard/research/dubai/standard_brands" component={DubaiStandardBrands} />
                <Route exact path="/dashboard/research/dubai/fancy_brands" component={DubaiFancyBrands} />

                <Route exact path="/dashboard/research/london/validate" component={LondonValidate} />
                <Route exact path="/dashboard/research/london/khaz_brands" component={LondonKhazBrands} />
                <Route exact path="/dashboard/research/london/standard_brands" component={LondonStandardBrands} />
                <Route exact path="/dashboard/research/london/fancy_brands" component={LondonFancyBrands} />

                <Route exact path="/dashboard/research/paris/validate" component={ParisValidate} />
                <Route exact path="/dashboard/research/paris/khaz_brands" component={ParisKhazBrands} />
                <Route exact path="/dashboard/research/paris/standard_brands" component={ParisStandardBrands} />
                <Route exact path="/dashboard/research/paris/fancy_brands" component={ParisFancyBrands} />

                <Route exact path="/dashboard/research/istanbul/validate" component={IstanbulValidate} />
                <Route exact path="/dashboard/research/istanbul/khaz_brands" component={IstanbulKhazBrands} />
                <Route exact path="/dashboard/research/istanbul/standard_brands" component={IstanbulStandardBrands} />
                <Route exact path="/dashboard/research/istanbul/fancy_brands" component={IstanbulFancyBrands} />

                <Route exact path="/dashboard/research/canada/validate" component={CanadaValidate} />
                <Route exact path="/dashboard/research/canada/khaz_brands" component={CanadaKhazBrands} />
                <Route exact path="/dashboard/research/canada/standard_brands" component={CanadaStandardBrands} />
                <Route exact path="/dashboard/research/canada/fancy_brands" component={CanadaFancyBrands} />

                <Route exact path="/dashboard/research/tehran/validate" component={TehranValidate} />
                <Route exact path="/dashboard/research/tehran/khaz_brands" component={TehranKhazBrands} />
                <Route exact path="/dashboard/research/tehran/standard_brands" component={TehranStandardBrands} />
                <Route exact path="/dashboard/research/tehran/fancy_brands" component={TehranFancyBrands} />

                <Route exact path="/dashboard/research/kingcounty/validate" component={KingcountyValidate} />
                <Route exact path="/dashboard/research/kingcounty/khaz_brands" component={KingcountyKhazBrands} />
                <Route exact path="/dashboard/research/kingcounty/standard_brands" component={KingcountyStandardBrands} />
                <Route exact path="/dashboard/research/kingcounty/fancy_brands" component={KingcountyFancyBrands} />

                <Route exact path="/dashboard/research/google" component={GoogleIndex} />

                <Route exact path="/dashboard/research/reviews/places" component={Places} />
                <Route exact path="/dashboard/research/reviews/base_reviews" component={BaseReviewsIndex} />
                <Route exact path="/dashboard/research/reviews/our_reviews" component={OurReviewsIndex} />

                <Route
                    path="/dashboard/survey/form/branch"
                    render={(props) => (
                        <BranchSelector {...props} title="Select a Branch - Survey Form" redirectUrl="/dashboard/survey/form"/>
                    )}
                />
                <Route path="/dashboard/survey/form/:branchId/manage" component={Survey} />

                <Route
                    path="/dashboard/survey/reports/branch"
                    render={(props) => (
                        <BranchSelector {...props} title="Select a Branch - Survey Reports" redirectUrl="/dashboard/survey/reports"/>
                    )}
                />
                <Route path="/dashboard/survey/reports/:branchId/manage" component={SurveyReports} />


                <Route exact path="/dashboard/financial-discount/coupons/discount_presets" component={ManageDiscountPresets} />
                <Route exact path="/dashboard/financial-discount/coupons/generate_presets" component={ManageGeneratePresets} />
                <Route exact path="/dashboard/financial-discount/coupons/generate_index" component={GenerateIndex} />
                <Route exact path="/dashboard/financial-discount/coupons/approve" component={ApproveCoupons} />

                <Route
                    path="/dashboard/financial-discount/coupons/reports/branch"
                    render={(props) => (
                        <BranchSelector {...props} title="Select a Branch - Coupon Reports" redirectUrl="/dashboard/financial-discount/coupons/reports"/>
                    )}
                />
                <Route path="/dashboard/financial-discount/coupons/reports/:branchId/manage" component={CouponReports} />

                <Route exact path="/dashboard/hr/setup/departments/manage" component={ManageDepartments} />

                <Route exact path="/dashboard/forms/create" component={FormBuilder} />
                <Route exact path="/dashboard/forms/manage" component={FormManagement} />
                <Route exact path="/dashboard/forms/manage/:id/render" component={FormRenderer} />
                <Route exact path="/dashboard/forms/manage/:id/edit" component={FormBuilder} />
                <Route exact path="/dashboard/forms/manage/:id/submissions" component={FormSubmissions} />
                <Route exact path="/dashboard/forms/manage/:formId/submissions/:submissionId" component={FormSubmissionViewer} />

                <Route exact path="/dashboard/test" component={MasterComponent} />

                <Route
                    path="/dashboard/operations/daily-logs/inventory/end-of-day/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for End-of-Day Inventory"
                            redirectUrl="/dashboard/operations/daily-logs/inventory/end-of-day/0"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/inventory/end-of-day/:confirmation/:branchId/manage" component={InventoryEndOfDay} />

                <Route
                    path="/dashboard/operations/daily-logs/inventory/start-of-day/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Start-of-Day Inventory"
                            redirectUrl="/dashboard/operations/daily-logs/inventory/start-of-day/1"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/inventory/start-of-day/:confirmation/:branchId/manage" component={InventoryEndOfDay} />

                <Route
                    path="/dashboard/operations/daily-logs/end-of-shift/submit-report/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Start-of-Day Inventory"
                            redirectUrl="/dashboard/operations/daily-logs/end-of-shift/submit-report"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/end-of-shift/submit-report/:branchId/manage" component={CreateEndOfShiftReport} />



                {/*****************************************************************************************************************************************/}
                <Route path="/dashboard/operations/setup/inventory/temperature-zones/:branchId/create" component={CreateEditTemperatureZone} />
                <Route path="/dashboard/operations/setup/inventory/temperature-zone/:branchId/:zoneId" component={CreateEditTemperatureZone} />

                <Route
                    path="/dashboard/operations/setup/inventory/temperature-zones/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Start-of-Day Inventory"
                            redirectUrl="/dashboard/operations/setup/inventory/temperature-zones"
                        />
                    )}
                />
                <Route path="/dashboard/operations/setup/inventory/temperature-zones/:branchId/manage" component={ListTemperatureZones} />
                {/*****************************************************************************************************************************************/}

                {/*****************************************************************************************************************************************/}
                <Route path="/dashboard/operations/daily-logs/end-of-shift/report/:reportId" component={EndOfShiftReportShow} />

                <Route
                    path="/dashboard/operations/daily-logs/end-of-shift/reports/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for End-of-Shift Inventory"
                            redirectUrl="/dashboard/operations/daily-logs/end-of-shift/reports"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/end-of-shift/reports/:branchId/manage" component={EndOfShiftReportsIndex} />
                {/*****************************************************************************************************************************************/}


                {/*****************************************************************************************************************************************/}
                <Route
                    path="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch to Submit a Temperature Report"
                            redirectUrl="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create/:branchId/manage" component={CreateShiftTemperatureReport} />

                <Route
                    path="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for View Temperature Reports"
                            redirectUrl="/dashboard/operations/daily-logs/end-of-shift/temperature-reports"
                        />
                    )}
                />
                <Route path="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/:branchId/manage" component={ListShiftTemperatureReports} />
                {/*****************************************************************************************************************************************/}





                {/*****************************************************************************************************************************************/}
                <Route path="/dashboard/hr/setup/training-topics/:branchId/create" component={CreateEditTrainingTopics} />
                <Route path="/dashboard/hr/setup/training-topic/:branchId/:topicId" component={CreateEditTrainingTopics} />

                <Route
                    path="/dashboard/hr/setup/training-topics/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Managing Training Topics"
                            redirectUrl="/dashboard/hr/setup/training-topics"
                        />
                    )}
                />
                <Route path="/dashboard/hr/setup/training-topics/:branchId/manage" component={ListTrainingTopics} />
                {/*****************************************************************************************************************************************/}





                {/*****************************************************************************************************************************************/}
                <Route path="/dashboard/hr/training-evaluation/:trainingId/topics" component={UserTrainingTopics} />

                <Route
                    path="/dashboard/hr/training-evaluation/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Managing Training Employees"
                            redirectUrl="/dashboard/hr/training-evaluation"
                        />
                    )}
                />
                <Route path="/dashboard/hr/training-evaluation/:branchId/manage" component={UserTrainings} />

                <Route path="/dashboard/hr/training-evaluation/:userTrainingId/create" component={SubmitTrainingEvaluation} />

                <Route path="/dashboard/hr/training-evaluation/:userTrainingId/evaluations" component={ShowTrainingEvaluations} />

                {/*****************************************************************************************************************************************/}




                <Route
                    path="/dashboard/operations/order-management/inventory/order/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Ordering Inventories"
                            redirectUrl="/dashboard/operations/order-management/inventory/order"
                        />
                    )}
                />
                <Route path="/dashboard/operations/order-management/inventory/order/:branchId/manage" component={OrderPage} />


                <Route
                    path="/dashboard/operations/order-management/inventory/branch-orders/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Tracking Branch Orders"
                            redirectUrl="/dashboard/operations/order-management/inventory/branch-orders"
                        />
                    )}
                />
                <Route path="/dashboard/operations/order-management/inventory/branch-orders/:branchId/manage" component={BranchOrders} />

                <Route path="/dashboard/operations/order-management/inventory/vendor-orders" component={VendorOrders} />

                <Route
                    path="/dashboard/settings/forecast/coefficients/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            customComponent={<RecalculateAllCoefficients />}
                            title="Select a Branch to View Coefficients"
                            redirectUrl="/dashboard/settings/forecast/coefficients"
                        />
                    )}
                />
                <Route path="/dashboard/settings/forecast/coefficients/:branchId/manage" component={BranchCoefficientsComponent} />

                <Route
                    path="/dashboard/operations/order-management/forecast/forecasts/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch to Manage Forecasts"
                            redirectUrl="/dashboard/operations/order-management/forecast/forecasts"
                        />
                    )}
                />
                <Route path="/dashboard/operations/order-management/forecast/forecasts/:branchId/manage" component={BranchForecasts} />

                <Route path="/dashboard/settings/forecast/settings" component={BranchSettingsComponent} />
                <Route path="/dashboard/settings/forecast/models" component={ModelPerformanceComponent} />

                <Route exact path="/dashboard/settings/terminals/devices" component={DeviceListing} />
                <Route exact path="/dashboard/settings/terminals/devices/create" component={DeviceForm} />
                <Route exact path="/dashboard/settings/terminals/devices/:id/edit" component={DeviceForm} />

                <Route exact path="/dashboard/operations/setup/inventory/manage" component={ManageInventories} />
                <Route exact path="/dashboard/operations/setup/inventory/new" component={CreateInventory} />
                <Route exact path="/dashboard/operations/setup/inventory/:id/edit" component={CreateInventory} />

                <Route exact path="/dashboard/operations/setup/inventory/vendors/manage" component={ManageVendors} />
                <Route exact path="/dashboard/operations/setup/inventory/vendors/new" component={CreateVendor} />
                <Route exact path="/dashboard/operations/setup/inventory/vendors/:id/edit" component={CreateVendor} />

                <Route exact path="/dashboard/operations/setup/inventory/containers/manage" component={ManageContainers} />
                <Route exact path="/dashboard/operations/setup/inventory/containers/new" component={CreateOrEditContainer} />
                <Route exact path="/dashboard/operations/setup/inventory/containers/:id/edit" component={CreateOrEditContainer} />

                <Route exact path="/dashboard/operations/setup/inventory/branch/manage" component={ManageBranchInventories} />
                <Route exact path="/dashboard/operations/setup/inventory/branch/new" component={CreateBranchInventory} />
                <Route exact path="/dashboard/operations/setup/inventory/branch/:id/edit" component={CreateBranchInventory} />

                <Route exact path="/dashboard/operations/setup/inventory/branch-group/manage" component={ManageBranchInventoryGroups} />
                <Route exact path="/dashboard/operations/setup/inventory/branch-group/new" component={CreateBranchInventoryGroup} />
                <Route exact path="/dashboard/operations/setup/inventory/branch-group/:id/edit" component={CreateBranchInventoryGroup} />

                <Route exact path="/dashboard/operations/setup/inventory/branch-containers/manage" component={ManageBranchInventoryGroupContainers} />
                <Route exact path="/dashboard/operations/setup/inventory/branch-containers/new" component={CreateOrEditBranchInventoryGroupContainer} />
                <Route exact path="/dashboard/operations/setup/inventory/branch-containers/:id/edit" component={CreateOrEditBranchInventoryGroupContainer} />

                <Route
                    path="/dashboard/operations/reports-analytics/inventory/reports/branch"
                    render={(props) => (
                        <BranchSelector
                            {...props}
                            title="Select a Branch for Reports"
                            redirectUrl="/dashboard/operations/reports-analytics/inventory/reports"
                        />
                    )}
                />
                <Route path="/dashboard/operations/reports-analytics/inventory/reports/:branchId/manage" component={BranchDailyRecords} />

                <Route path="/dashboard/operations/setup/inventory/email-templates" exact component={EmailTemplatesList} />
                <Route path="/dashboard/operations/setup/inventory/email-templates/vendor/edit" component={VendorEmailTemplateEditor} />
                <Route path="/dashboard/operations/setup/inventory/email-templates/vendor-order-cancel/edit" component={VendorOrderCancelTemplateEditor} />

            </AdminLayout>
        </Switch>
    );
};

export default DashboardRoutes;