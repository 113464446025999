import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import BranchInventoryGroup from '../../../../../http/BranchInventoryGroup';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import BranchInventory from '../../../../../http/BranchInventory';
import FormItem from "../../../../../components/Form/FormItem";

const { Option } = Select;

const CreateBranchInventoryGroup = () => {
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [errors, setErrors] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        BranchInventory.FetchInitialData((response) => {
            const { branches, vendors, inventories } = response.data.data;
            setBranches(branches);
            setVendors(vendors);
            setInventories(inventories);
            if (id) {
                fetchBranchInventoryGroup(id);
            } else {
                setLoading(false);
            }
        }, (err) => {
            setBranches([]);
            setVendors([]);
            setInventories([]);
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load initial data',
            });
        });
    };

    const fetchBranchInventoryGroup = (id) => {
        BranchInventoryGroup.Edit(id, (response) => {
            const branchInventoryGroup = response.data.data.branch_inventory_group;
            form.setFieldsValue({
                ...branchInventoryGroup,
                inventories: branchInventoryGroup.branch_inventories.map(bi => bi.inventory.id),
            });
            setImageUrl(branchInventoryGroup.image_url);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branch inventory group',
            });
            history.push("/dashboard/operations/setup/inventory/branch-group/manage");
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);
        const formData = new FormData();

        Object.keys(values).forEach(key => {
            if (key === 'inventories') {
                formData.append(key, JSON.stringify(values[key]));
            } else {
                formData.append(key, values[key]);
            }
        });

        const file = values.image?.file;
        if (file) {
            formData.append('image', file);
        }


        if (id) {
            BranchInventoryGroup.Update(id, formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch-group/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setErrors(err.response?.data?.errors);
            });
        } else {
            BranchInventoryGroup.Store(formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch-group/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setErrors(err.response?.data?.errors);
            });
        }
    };

    const handleFileChange = ({file}) => {
        if (file.status === 'done' || file.status === 'removed') {
            setImageUrl(file.thumbUrl);
        }
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" style={{ backgroundColor: "#FAFAFA" }}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Branch Inventory Group' : 'Add New Branch Inventory Group'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish} encType="multipart/form-data">
                        <Form.Item
                            label="Branch"
                            name="branch_id"
                            rules={[{ required: true, message: 'Please select the branch' }]}
                        >
                            <Select placeholder="Select branch" disabled={submitting}>
                                {branches.map(branch => (
                                    <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter the group name' }]}
                        >
                            <Input disabled={submitting} />
                        </Form.Item>

                        <Form.Item
                            label="Vendor"
                            name="inventory_vendor_id"
                            rules={[{ required: true, message: 'Please select the vendor' }]}
                        >
                            <Select placeholder="Select vendor" disabled={submitting}>
                                {vendors.map(vendor => (
                                    <Option key={vendor.id} value={vendor.id}>{vendor.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Order Batch Size"
                            name="order_batch_size"
                            rules={[{ required: true, message: 'Please enter the order batch size' }]}
                        >
                            <Input disabled={submitting} />
                        </Form.Item>

                        <Form.Item
                            label="Unit Type"
                            name="unit_type"
                            rules={[{ required: true, message: 'Please select the unit type' }]}
                        >
                            <Select placeholder="Select unit type" disabled={submitting}>
                                <Option value="pc">pc</Option>
                                <Option value="gr">gr</Option>
                                <Option value="kg">kg</Option>
                                <Option value="ml">ml</Option>
                                <Option value="l">l</Option>
                            </Select>
                        </Form.Item>

                        <FormItem
                            label="Inventories"
                            name="inventories"
                            rules={[{ required: true, message: 'Please select inventories' }]}
                            errors={errors}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select inventories"
                                disabled={submitting}
                                optionFilterProp="children"
                                showSearch
                                allowClear
                            >
                                {inventories.map(inventory => (
                                    <Option key={inventory.id} value={inventory.id}>{inventory.name} ({inventory.unit_type})</Option>
                                ))}
                            </Select>
                        </FormItem>

                        {/* Description Field */}
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please enter a description' }]}
                        >
                            <Input.TextArea rows={4} disabled={submitting} />
                        </Form.Item>

                        {/* Image Upload */}
                        <Form.Item label="Image" name="image">
                            <Upload
                                name="image"
                                listType="picture"
                                maxCount={1}
                                showUploadList={true}
                                beforeUpload={() => false} // Prevent auto-upload
                                onChange={handleFileChange}
                                disabled={submitting}
                            >
                                <Button icon={<UploadOutlined />} disabled={submitting}>Upload Image</Button>
                            </Upload>
                        </Form.Item>

                        {imageUrl && (
                            <FormItem>
                                <img src={imageUrl} alt="Container" className={"max-w-64"}/>
                            </FormItem>
                        )}


                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full">
                                {id ? 'Update Branch Inventory Group' : 'Add Branch Inventory Group'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateBranchInventoryGroup;
