import React from 'react';
import { Card, List, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const EmailTemplatesList = () => {
    const history = useHistory();

    const templates = [
        {
            title: 'Vendor Order Confirmation',
            description: 'This template is used to confirm vendor orders.',
            slug: 'vendor',
        },
        {
            title: 'Vendor Order Cancellation',
            description: 'This template is used to notify vendors when an order is cancelled.',
            slug: 'vendor-order-cancel',
        }
    ];

    const handleEdit = (slug) => {
        history.push(`/dashboard/operations/setup/inventory/email-templates/${slug}/edit`);
    };

    return (
        <div className="container flex flex-col mx-auto p-4 mt-5 md:mt-0">
            <h2 className="text-2xl font-bold mb-4">Email Templates</h2>
            <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={templates}
                renderItem={item => (
                    <List.Item>
                        <Card
                            title={item.title}
                            extra={<Button type="primary" onClick={() => handleEdit(item.slug)}>Edit</Button>}
                        >
                            <p>{item.description}</p>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default EmailTemplatesList;
