import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Button, notification, Spin } from 'antd';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import moment from 'moment';

const EndOfShiftReportsIndex = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 });
    const [sorter, setSorter] = useState({ field: 'created_at', order: 'desc' });

    useEffect(() => {
        loadReports(pagination, sorter, true); // Show big spinner for initial load
    }, [branchId]);

    const loadReports = (paginationParams, sorterParams, initialLoad = false) => {
        if (initialLoad) {
            setLoading(true);
        } else {
            setTableLoading(true);
        }

        BranchInventory.IndexEndOfShiftReports(
            branchId,
            {
                params: {
                    per_page: paginationParams.pageSize,
                    page: paginationParams.current,
                    sort_by: sorterParams.field,
                    sort_direction: sorterParams.order === 'ascend' ? 'asc' : 'desc',
                },
            },
            (response) => {
                if (response && response.data && response.data.data) {
                    const { data } = response.data;
                    setReports(data.reports.data);
                    setPagination({
                        ...paginationParams,
                        total: data.reports.total,
                    });
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Unexpected response format while loading end of shift reports.',
                    });
                }
                setLoading(false);
                setTableLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load end of shift reports.',
                });
                setLoading(false);
                setTableLoading(false);
            }
        );
    };

    const handleTableChange = (paginationConfig, _, sorterConfig) => {
        const updatedPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total
        };

        const updatedSorter = {
            field: sorterConfig.field || sorter.field,
            order: sorterConfig.order || sorter.order,
        };

        setPagination(updatedPagination);
        setSorter(updatedSorter);

        loadReports(updatedPagination, updatedSorter); // Load reports with updated pagination and sorting
    };

    const columns = [
        {
            title: 'User',
            dataIndex: ['user', 'name'],
            key: 'user',
            sorter: true,
            sortOrder: sorter.field === 'user.name' && sorter.order,
        },
        {
            title: 'Date of Report',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            sortOrder: sorter.field === 'date' && sorter.order,
        },
        {
            title: 'Submitted At',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            sortOrder: sorter.field === 'created_at' && sorter.order,
            render: (created_at) => moment(created_at).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Link to={`/dashboard/operations/daily-logs/end-of-shift/report/${record.id}`}>
                    <Button type="link">View Report</Button>
                </Link>
            ),
        },
    ];

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2>End of Shift Reports</h2>
                <Table
                    columns={columns}
                    dataSource={reports}
                    rowKey="id"
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        pageSizeOptions: ['5', '10', '20'],
                        showSizeChanger: true,
                    }}
                    onChange={handleTableChange}
                    loading={tableLoading}
                />
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default EndOfShiftReportsIndex;
